import { acceptTerm } from './accept-terms';
import { getAllTerms } from './get-all-company-terms';
import { getCompanyContract } from './get-company-contract';
import { getCompanyNotifications } from './get-company-notifications';
import { getCompanyOverview } from './get-company-overview';
import { setCompanyNotificationsToReaded } from './set-company-notifications-to-readed';
import { updateCompanyStateDocument } from './update-company-documento-estadual';
import { updateCompanyCertificate } from './update-company-certificate';
import { updateCompanyInfo } from './update-company-profile';
import { getBusesGrades } from './get-buses-grades';
import { getDriversGrades } from './get-drivers-grades';
import { getGroupsGrades } from './get-groups-grades';
import { getCompanyBuses } from './get-company-buses';
import { getCompanyBus } from './get-company-bus';
import { getRoutesFromBus } from './get-routes-from-bus';
import { removeBus } from './remove-bus';
import { saveBus } from './save-bus';
import { updateBus } from './update-bus';
import { createBus } from './create-bus';
import { updateUser } from './update-user';
import { getCities } from './get-cities-in-uf';
import { getGroupsList } from './get-groups-list';
import { setGroupDrivers } from './set-group-drivers';
import { getCompanyMonthInvoices } from './get-company-month-invoices';
import { getCompanyInvoicesDownloadLinkByMonth } from './get-company-invoices-download-link-by-month';
import { removeDriverPermission } from './remove-driver-permission';
import { getRoutesFromDriver } from './get-routes-from-driver';
import { getDriverById } from './get-driver-by-id';
import { saveDriver } from './save-driver';
import { extratoCarteira } from './extrato-carteira';
import { getSaldoFuturoMarketplace } from './get-saldo-futuro-marketplace';
import { getNovoRelatorioFinanceiroMarketplace, getDataConfiguracaoPagamentoRecente }
  from './get-relatorio-financeiro-marketplace';
import { getBankAccount } from './get-bank-account';
import { saque } from './saque';
import { acceptContract } from './accept-contract';
import { getContract } from './get-contract';
import { checkPendingTerms } from './check-pending-terms';
import { setGroupBus } from './set-group-bus';
import { askSetGroupBus } from './ask-set-group-bus';
import { checkDriversAvaliability } from './check-drivers-avaliability';
import { checkDriversAvaliabilityNew } from './check-drivers-avaliability-new';
import { updateGroupClasses } from './update-group-classes';
import { batchSetGruposClosed } from './batch-set-grupos-closed';
import { getGroupRouteLegClasses } from './get-group-route-leg-classes';
import { getBPES } from './get-bpes';
import { getGroupPrices } from './get-group-prices';
import { updateGroupPrices } from './update-group-prices';
import { getGroupPricesLogs } from './get-group-prices-logs';
import { getSuggestedICMS } from './get-suggested-icms';
import { automaticInvoice } from './automatic-invoice';
import { simulateInvoiceCancelation } from './simulate-invoice-cancelation';
import { validateInvoice } from './validate-invoice';
import { uploadInvoice } from './upload-invoice';
import { generateArtesp } from './generate-artesp';
import { listAnttDrivers } from './list-antt-drivers';
import { generateAntt } from './generate-antt';
import { printAntt } from './print-antt';
import { manualGroupAuthorization } from './manual-group-authorization';
import { getDebitNote } from './get-debit-note';

import { getDriverGroups } from './get-driver-groups';
import { dermgLogin } from './dermg-login';
import { dermgGenerate } from './dermg-generate';
import { getGroupsInverseRoute } from './get-groups-inverse-route';
import { getCompanyTollsInfo } from './get-tolls-info';

import { getFinancialPenaltiesByMechanicalFailureFromLastWeek }
  from './get-financial-penalties-by-mechanical-failure-from-last-week';

import { getMultaList } from './get-multa-list';
import { getMulta } from './get-multa';
import { createContestacao } from './create-contestacao';

const CompanyServices = {
  acceptTerm,
  getAllTerms,
  getCompanyContract,
  getCompanyNotifications,
  getCompanyOverview,
  getCompanyMonthInvoices,
  setCompanyNotificationsToReaded,
  updateCompanyStateDocument,
  updateCompanyCertificate,
  updateCompanyInfo,
  getBusesGrades,
  getDriversGrades,
  getGroupsList,
  getGroupRouteLegClasses,
  getGroupPrices,
  getGroupPricesLogs,
  getGroupsGrades,
  getCompanyBuses,
  getCompanyBus,
  getRoutesFromBus,
  removeBus,
  saveBus,
  updateBus,
  createBus,
  getCities,
  setGroupDrivers,
  updateUser,
  getCompanyInvoicesDownloadLinkByMonth,
  removeDriverPermission,
  getRoutesFromDriver,
  getDriverById,
  saveDriver,
  extratoCarteira,
  getSaldoFuturoMarketplace,
  getNovoRelatorioFinanceiroMarketplace,
  getDataConfiguracaoPagamentoRecente,
  getBankAccount,
  saque,
  acceptContract,
  getContract,
  checkPendingTerms,
  generateArtesp,
  listAnttDrivers,
  generateAntt,
  printAntt,
  manualGroupAuthorization,
  setGroupBus,
  askSetGroupBus,
  checkDriversAvaliability,
  checkDriversAvaliabilityNew,
  updateGroupClasses,
  batchSetGruposClosed,
  getBPES,
  updateGroupPrices,
  getSuggestedICMS,
  automaticInvoice,
  simulateInvoiceCancelation,
  validateInvoice,
  uploadInvoice,
  getDriverGroups,
  dermgLogin,
  dermgGenerate,
  getGroupsInverseRoute,
  getCompanyTollsInfo,
  getDebitNote,
  getFinancialPenaltiesByMechanicalFailureFromLastWeek,
  getMultaList,
  getMulta,
  createContestacao,
};

export { CompanyServices };
