import { defineStore } from 'pinia';
import { createCopy } from '@utils/object/create-copy';
import { CompanyServices } from '@services/company-services';
import { useTravelsPage } from '@stores';

export const useTravelBatchSetGruposClosedModal = defineStore('TravelBatchFecharGruposModal', {
  state: () => ({
    isModalOpen: false,
    isClosed: false,
    groups: [],
    groupsToEdit: [],
    isLoading: false,
  }),
  getters: {
    canSave() {
      if (this.isClosed) {
        return this.groups.some((group) => group.classes.some((classe) => !classe.closed));
      }
      return this.groups.some((group) => group.classes.some((classe) => classe.closed));
    },
  },
  actions: {
    open(groups, isClosed) {
      this.groups = createCopy(groups);
      this.groupsToEdit = createCopy(groups);
      this.cancelDialogIsOpen = false;
      this.isModalOpen = true;
      this.isClosed = isClosed;
    },
    close() {
      this.groups = null;
      this.groupsToEdit = null;
      this.cancelDialogIsOpen = false;
      this.isModalOpen = false;
    },
    requestUpdateClassesBatch() {
      this.isLoading = true;
      const groupsIds = this.groups.map((group) => group.id);
      const TravelsPage = useTravelsPage();
      return CompanyServices.batchSetGruposClosed(groupsIds, this.isClosed)
        .then(() => {
          TravelsPage.requestGroups();
          this.close();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
});
