import { CompanyAPI } from '@api';

const batchSetGruposClosed = (groupsIds, isClosed) => {
  const params = {
    groupsIds,
    isClosed,
  };

  return CompanyAPI.batchSetGruposClosed(params)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response));
};

export { batchSetGruposClosed };
